import * as React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelopeOpen, faMapMarker } from '@fortawesome/free-solid-svg-icons'

import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
// import { MDXRenderer } from 'gatsby-plugin-mdx'

import AnimationWrapper from "./animationWrapper"
import Button from "./button"
import Container from "./container"
import Title from "./title"

import "./contactDataSection.css"

const ContactDataSection = ( ) => {

  const data = useStaticQuery(
    graphql`
      query {
        contactData: allMdx(
          filter: {internal: {contentFilePath: {regex: "/contact-data/"}}}
          sort: { fields: [frontmatter___order], order: ASC }
        ) {
            group(field: frontmatter___department) {
              edges {
                node {
                  body
                  frontmatter {
                    department
                    order
                    name
                    position
                    email
                    phone
                    phone_alt
                    phone_postfix
                    region
                    address{
                      street
                      postal_code
                      locality
                      region
                    }
                    avatar {
                      childImageSharp {
                        gatsbyImageData(
                          webpOptions: {quality:95}
                          width: 110
                          height: 110
                        )
                      }
                    }
                  }
                }
            }
          }
        }
        contactDepartments: allMdx(
          filter: {internal: {contentFilePath: {regex: "/contact-departments/"}}}
          sort: { fields: [frontmatter___order], order: ASC }
        ) {
          nodes {
            frontmatter {
              name
              order
              slug
            }
          }
        }
        lion_bg: file(relativePath: {eq: "lion-contact-bg.png"}) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 95
              transformOptions: {fit: COVER}
              webpOptions: {quality: 95}
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const exceptions = "biuro-projektowe";

  // sort contacts and departments
  const sortedContacts = data.contactData.group.sort((a, b) => a.edges[0].node.frontmatter.department_order - b.edges[0].node.frontmatter.department_order);
  const departments = data.contactDepartments.nodes;
  sortedContacts.forEach(function(contact) {
    departments.forEach(function(department) {
      if(contact.edges[0].node.frontmatter.department === department.frontmatter.slug){
        contact.name = department.frontmatter.name;
        contact.order = department.frontmatter.order;
        contact.slug = department.frontmatter.slug;
      }
    });
  });
  sortedContacts.sort((a, b) => a.order - b.order);


  return (

      <section className="contact-data-section page-section">

      {sortedContacts.map((department) => (
        <AnimationWrapper id={department.slug}>
          <Title className="contact-section-title" title={department.name} />
          <div className="contact-data-wrapper">
            {!exceptions.includes(department.edges[0].node.frontmatter.department) &&
              <Container className="contact-data-container">
                {department.edges.map((contactCard) => (
                  <AnimationWrapper className="contact-card" animationType="fadeInUp">
                    {contactCard.node.frontmatter.avatar &&
                      <div className="avatar-wrapper">
                        <GatsbyImage
                          image={contactCard.node.frontmatter.avatar.childImageSharp.gatsbyImageData}
                          alt={contactCard.node.frontmatter.name}
                        />
                      </div>
                    }
                    <div className="contact-data">
                      <div className="name">{contactCard.node.frontmatter.name}</div>
                      <div className="position">{contactCard.node.frontmatter.position}</div>
                      {contactCard.node.frontmatter.region && <div className="region">{contactCard.node.frontmatter.region}</div>}
                      {/* {contactCard.node.frontmatter.address.street &&
                        <div className="address">
                          <FontAwesomeIcon icon={faMapMarker} />
                          <span> {contactCard.node.frontmatter.address.street}, {contactCard.node.frontmatter.address.postal_code} {contactCard.node.frontmatter.address.locality}</span>
                        </div>
                      } */}
                      {contactCard.node.frontmatter.phone &&
                        <div className="phone"><FontAwesomeIcon icon={faPhone} /><a href={`tel:${contactCard.node.frontmatter.phone}`}> {contactCard.node.frontmatter.phone}</a></div>
                      }
                      {contactCard.node.frontmatter.phone_alt &&
                        <div className="phone-alt"><FontAwesomeIcon icon={faPhone} /><a href={`tel:${contactCard.node.frontmatter.phone_alt}`}> {contactCard.node.frontmatter.phone_alt} {contactCard.node.frontmatter.phone_postfix && <span> {contactCard.node.frontmatter.phone_postfix}</span>}
                        </a></div>
                      }
                      {contactCard.node.frontmatter.email &&
                        <div className="email"><FontAwesomeIcon icon={faEnvelopeOpen} /><a href={`mailto:${contactCard.node.frontmatter.email}`}> {contactCard.node.frontmatter.email}</a></div>
                      }
                    </div>
                  </AnimationWrapper>
                ))}
              </Container>
            }
            {exceptions.includes(department.edges[0].node.frontmatter.department) &&
              <Container className="contact-data-container description-department">
                {department.edges.map((contactCard) => (
                  <div className="contact-department-desc-wrapper">
                    <AnimationWrapper className="contact-data" animationType="fadeInUp">
                      <h4 className="department-desc-title">{contactCard.node.frontmatter.name}</h4>
                      <p className="department-desc">
                        {contactCard.node.body}
                      </p>
                      <div className="contact-card not-named">
                        {contactCard.node.frontmatter.address?.street &&
                          <div>
                            <FontAwesomeIcon icon={faMapMarker} />
                            <span> {contactCard.node.frontmatter.address?.street}, {contactCard.node.frontmatter.address?.postal_code} {contactCard.node.frontmatter.address?.locality}</span>
                          </div>
                        }
                        {contactCard.node.frontmatter.phone && <div><FontAwesomeIcon icon={faPhone} /><a href={`tel:${contactCard.node.frontmatter.phone}`}> {contactCard.node.frontmatter.phone}</a></div>}
                        {contactCard.node.frontmatter.email && <div><FontAwesomeIcon icon={faEnvelopeOpen} /><a href={`mailto:${contactCard.node.frontmatter.email}`}> {contactCard.node.frontmatter.email}</a></div>}
                      </div>
                    </AnimationWrapper>
                  </div>
                ))}
              </Container>
            }

            {department.slug === 'dzial-handlowy' &&
              <AnimationWrapper className="redirect-to-map" animationType="fadeInUp">
                <Container>
                  <Title title="Nie znalazłeś swojej miejscowości?" />
                  <Button btnStyle="color-dark" btnLink="/kontakt#contact-map-interactive-section" btnText="Wyszukaj dowolną lokalizacje na interaktywnej mapie!" />
                </Container>
              </AnimationWrapper>
            }

            <div className="lion-bg-container">
              <GatsbyImage image={data.lion_bg.childImageSharp.gatsbyImageData} />
            </div>
          </div>
        </AnimationWrapper>
      ))}

      <Title className="contact-section-title" title="&nbsp;" />

      </section>
  )
}
export default ContactDataSection